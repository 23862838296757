import { useState } from "react";

const validate = (validations, values) => {
    const errors = validations
        .map(validation => validation(values))
        .filter(validation => typeof validation === 'object');

    return {
        isValid: errors.length === 0,
        errors: errors.reduce((errors, error) => ({...errors, ...error}), {})
    };
}

export const getValidationMessage = (key, rules) => {
    const invalid = rules.filter(rule => !rule.term);

    if(invalid.length) {
        return { [key]: invalid[0].msg };
    }

    return true;
};
export const isRequired = value => value != null && value.trim().length > 0;
export const isSame = (value1, value2) => value1 === value2;
export const isEmail = (email) => Boolean(String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
export const isNumber = val => !isNaN(val);
export const isFilled = values => Boolean(values.length);
export const oneOf = array => array.some(i => i.toString() === "true");
export const everyOf = array => array.every(i => i.toString() === "true");

export const useForm = (initialState = {}, validations = [], onSubmit = () => {}) => {
    const {isValid: initialIsValid, errors: initialErrors} = validate(validations, initialState);
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [isValid, setValid] = useState(initialIsValid);
    const [isValidated, setIsValidated] = useState(false);
    const [touched, setTouched] = useState({});
    const [active, setActive] = useState('');

    const changeEventHandler = (event, prop = "value") => changeValueHandler(event.target.name, event.target[prop]);

    const changeValueHandler = (name, value) => {
        const newValues = {...values, [name]: value};
        const {isValid, errors} = validate(validations, newValues);
        setValues(newValues);
        setValid(isValid);
        setErrors(errors);
        setTouched({...touched, [name]: true});
        setActive(name);
        setIsValidated(false);
    };

    const reset = () => {
        setValues(initialState);
        setValid(initialIsValid);
        setErrors(initialErrors);
        setIsValidated(false);
        setTouched({});
        setActive('');
    };

    const submitHandler = event => {
        event.preventDefault();
        event.stopPropagation();
        setIsValidated(true);
        onSubmit(values, isValid, reset);
    }

    return { values, changeEventHandler, changeValueHandler, isValid, isValidated, errors, touched, active, submitHandler };
}
