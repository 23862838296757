import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, { defaultClassNames } from 'react-dropzone-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Preview = ({ fileWithMeta: file }) => (
    <div className="dzu-dropzone-preview file">
        <div className="name">{file.meta.name}</div>
        <button onClick={() => file.remove()}>
            <FontAwesomeIcon icon={faTrash} />
        </button>
    </div>
);

const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => (
    <div>
        {previews}
        <div {...dropzoneProps}>
            {files.length < maxFiles && input}
        </div>
    </div>
);

const UploadFiles = ({ setFiles }) => (
    <>
        <Dropzone
            onChangeStatus={(file, status, allFiles) => setFiles(allFiles.map(f => ({ file: f.file, name: f.meta.name})))}
            LayoutComponent={Layout}
            PreviewComponent={Preview}
            inputContent="Přesuňte nebo klikněte pro nahrání souborů"
            inputWithFilesContent="Přesuňte nebo klikněte pro nahrání dalších souborů"
            classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        />
        <p className="mt-2">Nahrávejte pouze soubory ve formátu docx, pdf, xls, jpg, avi, wav.</p>
    </>
);

export default UploadFiles;
