import React, { useState } from 'react';
import { useHistory } from "react-router";
import { fetch } from "utils/url";

const NewMessage = () => {
    const history = useHistory();
    const [code, setCode] = useState('');
    const go = (e) => {
        e.preventDefault();
        history.push(`/new-message/${code}`);
    };

    return (
        <div className="card">
            <p>
                Chcete-li podat nové oznámení, zadejte kód organizace a klikněte na NOVÉ OZNÁMENÍ.<br />
                Neznáte-li kód organizace, najdete ho na jejich internetových stránkách.
            </p>
            <form onSubmit={go} className="row">
                <div className="col-md-6">
                    <input type="code" placeholder="Kód organizace" value={code} onChange={e => setCode(e.target.value)} />
                </div>
                <div className="col-md-6">
                    <input type="submit" className="red" value="Nové oznámení" />
                </div>
            </form>
        </div>
    );
};

const ViewMessage = () => {
    const history = useHistory();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const login = (e) => {
        e.preventDefault();
        fetch({ url: 'authenticate', method: 'POST', body: { code, password }})
            .then(({ token, renewal }) => {
                localStorage.setItem('token', token);
                localStorage.setItem('renewal', renewal);
                history.push('/view-message');
            });
    };

    return (
        <div className="card">
            <p>
                Chcete-li sledovat řešení Vašeho již podaného oznámení, vyplňte přidělený kód a oznámení včetně hesla a klikněte na VSTOUPIT.
            </p>
            <form onSubmit={login}>
                <div className="row">
                    <div className="col-md-6">
                        <input type="code" placeholder="Kód oznámení" value={code} onChange={e => setCode(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <input type="password" placeholder="Heslo" value={password} onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="col-md-6">
                        <input type="submit" value="Vstoupit" />
                    </div>
                </div>
            </form>
        </div>
    );
};

const Login = () => (
    <div className="container mt-4">
        <h2 className="mb-4">Oznámení</h2>
        <NewMessage />
        <ViewMessage />
    </div>
);

export default Login;
