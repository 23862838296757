import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Routes/Router';
import { BrowserRouter } from "react-router-dom";

const App = () => (
    <BrowserRouter>
        <Router />
    </BrowserRouter>
);


export default App;
