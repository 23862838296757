import React from 'react';
import { Switch } from "react-router-dom";
import { Route } from "react-router";

import Homepage from "./Homepage";
import Login from "./Login";
import NewMessage from "./NewMessage";
import Register from "./Register";

const Router = () => (
    <Switch>
        <Route path="/register" component={Register} />
        <Route path="/new-message/:code" component={NewMessage} />
        <Route path="/login" component={Login} />
        <Route path="/" component={Homepage} exact />
    </Switch>
);

export default Router;
