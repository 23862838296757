import React from 'react';
import { useFetch } from "../../utils/hooks";

const Homepage = () => {
    const { data, isLoading } = useFetch('articles/article/1');

    if(isLoading) {
        return null;
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
    )
}

export default Homepage;
