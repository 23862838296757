import React, { useState } from 'react';
import LogoHeader from '../../assets/images/logo-header.svg';
import IcoMail from "../../assets/images/ico-mail.svg";
import IcoLock from "../../assets/images/ico-lock.svg";
import IcoIc from "../../assets/images/ico-ic.svg";
import Popup from "../../assets/images/popup.svg";
import GDPR from "../../assets/GDPR.pdf";

import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";

const Layout = ({ children }) => {
    const [expanded, setExpanded] = useState(false);
    const closeNav = () => {
        setExpanded(false);
        window.scrollTo({ top: 0, behavior: "auto" });
    };
    const scroll = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -121;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <>
            <Navbar expand="lg" expanded={expanded} onToggle={setExpanded}>
                <div className="container px-3">
                    <HashLink className="navbar-brand" smooth scroll={scroll} to="/#top">
                        <img src={LogoHeader} alt="logo header" className="img-fluid" width={200} />
                    </HashLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav ml-auto text-center text-lg-left">
                            <li className="nav-item active">
                                <HashLink
                                    to="/#whistle-blowing"
                                    title="Whistleblowing"
                                    smooth
                                    scroll={scroll}
                                    onClick={() => setExpanded(false)}
                                    className="nav-link"
                                >
                                    whistleblowing
                                </HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink
                                    to="/#implementation"
                                    title="Implementace"
                                    smooth
                                    scroll={scroll}
                                    onClick={() => setExpanded(false)}
                                    className="nav-link"
                                >
                                    implementace
                                </HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink
                                    to="/#about-us"
                                    title="O nás"
                                    smooth
                                    scroll={scroll}
                                    onClick={() => setExpanded(false)}
                                    className="nav-link"
                                >
                                    o nás
                                </HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink
                                    to="/#footer"
                                    title="Kontakt"
                                    smooth
                                    scroll={scroll}
                                    onClick={() => setExpanded(false)}
                                    className="nav-link"
                                >
                                    kontakt
                                </HashLink>
                            </li>
                            <li className="nav-item call-to-action">
                                <Link
                                    className="nav-link"
                                    title="Oznámení"
                                    to="/login"
                                    onClick={closeNav}
                                >
                                    oznámení
                                </Link>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            {children}
            <section className="bg-white" id="footer">
                <div className="before-footer">
                    <div className="container text-center text-lg-left">
                        <img src={LogoHeader} alt="logo header" width={240} className="img-fluid"/>
                    </div>
                </div>
                <footer>
                    <div className="container text-center text-lg-left position-relative">
                        <div id="popup">
                            <div className="popup-header">Máte dotazy?</div>
                            <img src={Popup} alt="popup" />
                            <div className="popup-call">Volejte</div>
                            <div className="popup-number">770 121 211</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 mb-3 mb-lg-0">
                                ICT Vysočina s.r.o.<br/>
                                Ždírec 99, 588 13
                            </div>
                            <div className="col-lg-3 mb-3 mb-lg-0">
                                <img src={IcoMail} alt="ico mail" className="mb-1" width={28} /> pistalka@pistalka.online<br/>
                                <img src={IcoLock} alt="ico lock" className="mb-1" width={28} /> <a href={GDPR} target="_blank" rel="noreferrer" className="text-white">GDPR [PDF, 349 kB]</a><br/>
                                <img src={IcoIc} alt="ico ic" width={28} /> 140 38 501
                            </div>
                            <div className="col-lg-3">
                                Zapsaný v obchodním rejstříku<br/>
                                Krajského soudu v Brně,<br/>
                                oddíl C, vložka 126269<br/>
                                ID datové schránky: ir2w9gq
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="copy">
                    ICT Vysočina @ 2022, All Rights Reserved<br/>
                    Webdesign by <a href="https://www.leclavera.cz" className="color-primary" target="_blank" rel="noreferrer">LE CLAVERA s.r.o.</a>
                </div>
            </section>
        </>
    );
}

export default Layout;
