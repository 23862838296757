import React, { useState } from 'react';
import { fetch, upload } from "../../utils/url";
import { useFetch } from "../../utils/hooks";
import { useHistory, useParams } from "react-router";
import { serializeToUrlQuery } from "../../utils/helpers";
import {
    getValidationMessage,
    isEmail,
    isNumber,
    isRequired,
    isSame,
    oneOf,
    useForm
} from "../../utils/useForm";
import { Form } from "react-bootstrap";
import { FormControl, FormCheck } from "../../Components/FormControl";
import UploadFiles from "../../Components/UploadFiles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const initialState = {
    pass: '',
    pass2: '',
    subject: '',
    content: '',
    userName: '',
    userPhone: '',
    userEmail: '',
    sendCode: false,
};

const validations = [
    ({ pass, pass2 }) => getValidationMessage('pass', [
        { msg: 'Heslo je povinné', term: isRequired(pass) },
        { msg: 'Hesla se musejí shodovat', term: isSame(pass, pass2) },
        { msg: 'Minimální délka hesla je 8 znaků', term: pass.length > 8 },
    ]),
    ({ pass2 }) => getValidationMessage('pass2', [{ msg: 'Potvrzení hesla je povinné', term: isRequired(pass2) }]),
    ({ subject }) => getValidationMessage('subject', [{ msg: 'Předmět oznámení je povinný', term: isRequired(subject) }]),
    ({ content }) => getValidationMessage('content', [{ msg: 'Text oznámení je povinný', term: isRequired(content) }]),
    ({ userPhone }) => getValidationMessage('userPhone', [
        { msg: 'Telefon kontaktní osoby musí být číslo', term: oneOf([!isRequired(userPhone), isNumber(userPhone)]) },
        { msg: 'Telefon kontaktní osoby musí obsahovat 9 číslic', term: oneOf([!isRequired(userPhone), userPhone.length === 9]) },
    ]),
    ({ userEmail }) => getValidationMessage('userEmail', [{ msg: 'E-mail nemá správný formát', term: oneOf([!isRequired(userEmail), isEmail(userEmail)]) }]),
];

const create = ({ history, organizationId, code, body, files, isValid }) => {
    const content =
        "Dovoluji si Vás vyrozumět o tom, že jsme přijali Vaše oznámení.\n\n " +
        "Chtěl bych Vás ubezpečit, že vzájemná komunikace je důvěrná, zabezpečená šifrováním a anonymní. " +
        "K poskytnutým informacím bude mít přístup pouze příslušná osoba.\n\n" +
        "Mgr. et Bc. Luboš Kliment";
    if(!isValid) {
        return;
    }

    fetch({ url: 'messenger/room', method: 'POST', body: { organizationId, code, ...body, files: files.map(f => f.name) }})
        .then(({ id: roomId }) => {
            const payload = fetch({ url: 'messenger/message', method: 'POST', body: { roomId, content, files: [], createdBy: 'admin' }})
                .then(() => fetch({ url: 'authenticate', method: 'POST', body: { code, password: body.pass }}))
                .then(({ token, renewal }) => {
                    localStorage.setItem('token', token);
                    localStorage.setItem('renewal', renewal);
                });

            if(files?.length) {
                payload.then(() => {
                    const formData = new FormData();
                    files.forEach(({ file, name }) => formData.append('file[]', file, name));
                    return upload({ url: `messenger/room/upload/${roomId}`, formData });
                });
            }

            return payload;
        })
        .then(() => history.push('/view-message'));
};

const NewMessage = () => {
    const history = useHistory();
    const { code } = useParams();
    const [files, setFiles] = useState([]);
    const { data, isLoading } = useFetch(`organizations/organization?${serializeToUrlQuery({ filter: { code: [code] } })}`);
    const { data: codeData, isLoading: codeIsGenerating } = useFetch('messenger/code');
    const organization = data?.[0];

    const onSubmit = (body, isValid) => create({ history, organizationId: organization.id, code: codeData.code, body, files, isValid });
    const { submitHandler, values, isValidated, isValid: isFormValid, changeEventHandler, errors } = useForm(initialState, validations, onSubmit);
    const formProps = { values, isValidated, changeEventHandler, errors };

    if(isLoading || codeIsGenerating) {
        return null;
    }

    if(!organization) {
        history.push('/login');
        return null;
    }

    return (
        <div className="container">
            <Form noValidate onSubmit={submitHandler}>
                <h2 className="my-4">Nové oznámení</h2>
                <div className="row">
                    <div className="col-md-3">
                        <div className="input header">
                            Název organizace
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="input">
                            {organization.name}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="input header">
                            Kód oznámení
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="input">
                            {codeData.code}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="input header">
                            Heslo oznámení
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="input unstyled">
                            <FormControl type="password" name="pass" placeholder="Zadejte heslo *" {...formProps} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="input header">
                            Potvrzení hesla
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="input unstyled">
                            <FormControl type="password" name="pass2" placeholder="Zadejte heslo ještě jednou *" {...formProps} />
                        </div>
                    </div>
                </div>
                <p className="mt-3 mb-5 ml-3">
                    Tento kód a heslo jsou důležité pro další sledování Vašeho oznámení.
                    <strong className="ml-2" style={{ color: '#c7072c' }}>ÚDAJE SI OPIŠTE NEBO ZAPAMATUJTE!</strong>
                </p>

                <div className="card mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-3">Oznámení</h4>
                        </div>

                        <div className="col-md-7">
                            <FormControl type="text" name="subject" placeholder="Předmět oznámení *" {...formProps} />
                        </div>
                        <div className="col-md-7">
                            <FormControl as="textarea" rows={7} name="content" placeholder="Text oznámení *" {...formProps} />
                        </div>
                        <div className="col-md-7 pt-3">
                            <UploadFiles setFiles={setFiles} />
                        </div>

                        <div className="col-md-12">
                            <h4 className="mt-4 mb-3">Kontaktní údaje oznamovatele</h4>
                        </div>

                        <div className="col-md-7">
                            <FormControl type="text" name="userName" placeholder="Jméno a příjmení (nepovinný údaj)" {...formProps} />
                        </div>
                        <div className="col-md-7">
                            <FormControl type="text" name="userPhone" placeholder="Telefon (nepovinný údaj)" {...formProps} />
                        </div>
                        <div className="col-md-7">
                            <FormControl type="email" name="userEmail" placeholder="E-mail (nepovinný údaj)" {...formProps} />
                        </div>
                        <div className="mt-5 mb-4 ml-3">
                            <h6>Informace o důvěrnosti informací</h6>
                            <p className="mb-0">
                                Vzájemná komunikace je důvěrná, zabezpečena šifrováním a anonymní.<br />
                                K poskytnutým informacím budou mít přístup pouze příslušné osoby.
                            </p>
                        </div>
                        <div className="col-md-7">
                            {Boolean(formProps.values.userEmail.length) && (
                                <FormCheck type="checkbox" name="sendCode" label="Odeslat kód oznámení na email" {...formProps} />
                            )}
                        </div>
                        <div className="col-md-7">
                            <button className="submit" disabled={isValidated && isFormValid}>
                                Odeslat{' '}
                                {isValidated && isFormValid && (
                                    <FontAwesomeIcon icon={faSpinner} pulse />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>* Údaje jsou povinné</div>
            </Form>
        </div>
    );
}

export default NewMessage;
