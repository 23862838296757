import axios from "axios";

const BASE_URL = `/api/`;

const getCommonFetchOptions = ({ source, url, method, body, customHeaders }) => {
    const getRangeHeader = () => {
        const [pathname, searchParams] = url.split('?');
        const params = new URLSearchParams(searchParams);
        const sanitizedRangeUrl = pathname.replace(/^(\/api\/)/,"");

        if(params.has('range')) {
            const range = JSON.parse(params.get('range')).join('-');
            return { range: `${sanitizedRangeUrl}=${range}` };
        }

        return {};
    }

    const options = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...getRangeHeader(),
            ...customHeaders,
        },
        url,
    };

    if(source) {
        options.cancelToken = source.token;
    }

    if(body) {
        options.data = JSON.stringify(body);
    }

    const token = localStorage.getItem('token');
    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }

    return options;
}

export const fetch = ({ url, method = 'GET', body = null, source = null, withHeaders = false, customHeaders = {} }) => {
    const BaseUrl = `${BASE_URL}${url}`;
    const request = () => axios(getCommonFetchOptions({ source, url: BaseUrl, method, body, customHeaders }));

    return new Promise(function (resolve, reject) {
        request()
            .then(res => resolve(withHeaders ? [res.data, res.headers] : res.data))
            .catch(res => {
                if (res.response.status && localStorage.getItem('renewal')) {
                    renewal()
                        .then(request)
                        .then(res => resolve(res.data));
                } else {
                    reject();
                }
            })
    });
};

export const upload = ({ url, formData }) => {
    const BaseUrl = `${BASE_URL}${url}`;
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };
    const request = () => axios.post(BaseUrl, formData, config);

    return new Promise(function (resolve, reject) {
        request()
            .then(res => resolve(res.data))
            .catch(res => {
                if (res.response.status && localStorage.getItem('renewal')) {
                    renewal()
                        .then(request)
                        .then(res => resolve(res.data));
                } else {
                    reject();
                }
            })
    });
};

const renewal = () => axios(getCommonFetchOptions({ url: `${BASE_URL}/renewal`, method: 'POST', body: { renewal: localStorage.getItem('renewal') }}))
    .then(({ data: { token, renewal } }) => {
        localStorage.setItem('token', token);
        localStorage.setItem('renewal', renewal);
    }).catch(() => {
        localStorage.clear();
        window.location.href = '/login';
    });

export const encodeDataToURL = (data) => Object
    .keys(data)
    .filter(item => data[item] !== null)
    .map(value => `${value}=${data[value].value}`)
    .join('&');
