import React from 'react';
import { Switch } from "react-router-dom";
import ProtectedRoute from "Components/ProtectedRoute";

import ViewMessage from './ViewMessage';

const Router = ({ match: { path } }) => (
    <Switch>
        <ProtectedRoute path={path} component={ViewMessage} />
    </Switch>
);

export default Router;
