import { Form } from "react-bootstrap";
import React from "react";

export const FormControl = ({ name, errors, values, isValidated, changeEventHandler, type, ...props }) => (
    <>
        <Form.Control
            type={type}
            name={name}
            value={values[name]}
            isValid={isValidated && !Boolean(errors[name])}
            isInvalid={isValidated && Boolean(errors[name])}
            onChange={changeEventHandler}
            {...props}
        />
        <Form.Control.Feedback className="ml-4" type="invalid">{errors[name]}</Form.Control.Feedback>
    </>
);

export const FormCheck = ({ name, errors, values, isValidated, changeEventHandler, type, label, ...props }) => (
    <>
        <Form.Check type={type}>
            <Form.Check.Input
                type={type}
                name={name}
                value={values[name]}
                isValid={isValidated && !Boolean(errors[name])}
                isInvalid={isValidated && Boolean(errors[name])}
                onChange={e => changeEventHandler(e, "checked")}
                {...props}
            />
            <Form.Check.Label>{label}</Form.Check.Label>
            <Form.Control.Feedback className="ml-2" type="invalid">{errors[name]}</Form.Control.Feedback>
        </Form.Check>
    </>
);
