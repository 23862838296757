import React from 'react';
import { Switch, Route } from "react-router-dom";
import ProtectedRouter from './Protected/Router';
import PublicRouter from './Public/Router';
import Layout from "./Layout/Layout";

const Router = () => (
    <Switch>
        <Layout>
            <Route path="/view-message" component={ProtectedRouter} />
            <Route path="/" component={PublicRouter} />
        </Layout>
    </Switch>
);

export default Router;
