import React, { useEffect, useState } from 'react';
import { fetch } from "../../utils/url";
import { getValidationMessage, isEmail, isNumber, isRequired, useForm } from "../../utils/useForm";
import { Alert, Form } from "react-bootstrap";

const IC_LENGTH = 8;
const initialState = {
    ic: '',
    name: '',
    address: '',
    userCount: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
};

const validations = [
    ({ ic }) => getValidationMessage('ic', [
        { msg: 'IČO je povinné', term: isRequired(ic) },
        { msg: 'IČO musí být číslo', term: isNumber(ic) },
        { msg: 'IČO musí obsahovat 8 číslic', term: ic.length === IC_LENGTH },
    ]),
    ({ name }) => getValidationMessage('name', [{ msg: 'Název organizace je povinný', term: isRequired(name) }]),
    ({ address }) => getValidationMessage('address', [{ msg: 'Adresa je povinná', term: isRequired(address) }]),
    ({ userCount }) => getValidationMessage('userCount', [
        { msg: 'Počet zaměstnanců je povinný', term: isRequired(userCount) },
        { msg: 'Počet zaměstnanců musí být číslo', term: isNumber(userCount) },
    ]),
    ({ contactName }) => getValidationMessage('contactName', [{ msg: 'Jméno a příjmení kontaktní osoby je povinné', term: isRequired(contactName) }]),
    ({ contactPhone }) => getValidationMessage('contactPhone', [
        { msg: 'Telefon kontaktní osoby je povinné', term: isRequired(contactPhone) },
        { msg: 'Telefon kontaktní osoby musí být číslo', term: isNumber(contactPhone) },
        { msg: 'Telefon kontaktní osoby musí obsahovat 9 číslic', term: contactPhone.length === 9 },
    ]),
    ({ contactEmail }) => getValidationMessage('contactEmail', [
        { msg: 'E-mail kontaktní osoby je povinné', term: isRequired(contactEmail) },
        { msg: 'E-mail nemá správný formát', term: isEmail(contactEmail) }
    ]),
];

const Register = () => {
    const [send, setSend] = useState(false);
    const onSubmit = (body, isValid, reset) => {
        if(isValid) {
            fetch({ url: 'mailer/register', method: 'post', body }).then(() => setSend(true));
            reset();
        }
    };
    const { values, active, isValidated, errors, changeEventHandler, changeValueHandler, submitHandler } = useForm(initialState, validations, onSubmit);

    useEffect(() => {
        if(active === 'ic' && values.ic.length === IC_LENGTH) {
            fetch({ url: `ares/${values.ic}`, method: 'GET' }).then(data => {
                changeValueHandler('name', data.name);
                changeValueHandler('address', data.address);
            });
        }
    }, [values]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="my-4">Registrační formulář</h2>
                </div>
            </div>
            <Form noValidate onSubmit={submitHandler}>
                <div className="card">
                    {send && (
                        <Alert variant="primary" onClose={() => setSend(false)} dismissible>
                            Data byla úspěšně odeslána.
                        </Alert>
                    )}
                    <h4 className="ml-1">Organizace</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Control
                                type="number"
                                name="ic"
                                placeholder="IČO *"
                                value={values.ic}
                                isValid={isValidated && !Boolean(errors.ic)}
                                isInvalid={isValidated && Boolean(errors.ic)}
                                onChange={changeEventHandler}
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.ic}</Form.Control.Feedback>
                        </div>
                        <div className="col-md-6">
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Název *"
                                value={values.name}
                                isValid={isValidated && !Boolean(errors.name)}
                                isInvalid={isValidated && Boolean(errors.name)}
                                onChange={changeEventHandler}
                                required
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.name}</Form.Control.Feedback>
                        </div>
                        <div className="col-md-6">
                            <Form.Control
                                type="text"
                                name="address"
                                placeholder="Adresa *"
                                value={values.address}
                                isValid={isValidated && !Boolean(errors.address)}
                                isInvalid={isValidated && Boolean(errors.address)}
                                onChange={changeEventHandler}
                                required
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.address}</Form.Control.Feedback>
                        </div>
                        <div className="col-md-6">
                            <Form.Control
                                type="number"
                                name="userCount"
                                placeholder="počet zaměstnanců *"
                                value={values.userCount}
                                isValid={isValidated && !Boolean(errors.userCount)}
                                isInvalid={isValidated && Boolean(errors.userCount)}
                                onChange={changeEventHandler}
                                required
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.userCount}</Form.Control.Feedback>
                        </div>
                    </div>
                    <h4 className="ml-1 mt-4">Kontaktní osoba</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Control
                                type="text"
                                name="contactName"
                                placeholder="Jméno a příjmení *"
                                value={values.contactName}
                                isValid={isValidated && !Boolean(errors.contactName)}
                                isInvalid={isValidated && Boolean(errors.contactName)}
                                onChange={changeEventHandler}
                                required
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.contactName}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Control
                                type="text"
                                name="contactPhone"
                                placeholder="Telefon *"
                                value={values.contactPhone}
                                isValid={isValidated && !Boolean(errors.contactPhone)}
                                isInvalid={isValidated && Boolean(errors.contactPhone)}
                                onChange={changeEventHandler}
                                required
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.contactPhone}</Form.Control.Feedback>
                        </div>
                        <div className="col-md-6">
                            <Form.Control
                                type="email"
                                name="contactEmail"
                                placeholder="Email *"
                                value={values.contactEmail}
                                isValid={isValidated && !Boolean(errors.contactEmail)}
                                isInvalid={isValidated && Boolean(errors.contactEmail)}
                                onChange={changeEventHandler}
                                required
                            />
                            <Form.Control.Feedback className="ml-4" type="invalid">{errors.contactEmail}</Form.Control.Feedback>
                        </div>
                    </div>
                    <button className="submit mt-3 ml-auto" type="submit">Odeslat</button>
                </div>
            </Form>
        </div>
    );
}

export default Register;
