import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFetch } from "utils/hooks";
import {fetch, upload} from "utils/url";
import decodeJwt from "jwt-decode";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import UploadFiles from "../../Components/UploadFiles";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const formatDate = (date) => (new Date(date)).toLocaleString('cs-CZ', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

const UploadModal = ({ show, onHide, setFiles }) => (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Nahrávání dokumentů
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="my-4">
                <UploadFiles setFiles={setFiles} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="submit" onClick={onHide}>Ok</button>
        </Modal.Footer>
    </Modal>
);

const NewMessage = ({ onReload }) => {
    const [files, setFiles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const { jti: roomId } = decodeJwt(localStorage.getItem('token'));
    const [content, setContent] = useState("");
    const textareaRef = useRef(null);
    const send = () => fetch({ url: 'messenger/message', method: 'POST', body: { roomId, content, files: files.map(f => f.name), createdBy: 'user' }})
        .then(() => {
            if(files?.length) {
                const formData = new FormData();
                files.forEach(({ file, name }) => formData.append('file[]', file, name));
                return upload({ url: `messenger/room/upload/${roomId}`, formData });
            }
        })
        .then(onReload)
        .then(() => {
            setContent("");
            setFiles([]);
        });

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }, [content]);

    return (
        <>
            <div className="auto-height-textarea-wrapper">
                <textarea
                    placeholder="Nová zpráva"
                    className="auto-height-textarea"
                    ref={textareaRef}
                    value={content}
                    onChange={e => setContent(e.target.value)}
                />
                <button className="submit mx-2" style={{ whiteSpace: 'nowrap' }} onClick={() => setModalOpen(true)}>
                    <FontAwesomeIcon icon={faPaperclip} /> soubory {Boolean(files.length) && (<>({files.length})</>)}
                </button>
                <button className="submit" onClick={send}>Odeslat</button>
            </div>
            <UploadModal setFiles={setFiles} show={modalOpen} onHide={() => setModalOpen(false)} />
        </>
    );
};

const MessengerList = () => {
    const scrollPosition = useRef({ x: 0, y: 0 });
    const [messageList, setMessageList] = useState([]);
    const { data, reload } = useFetch('messenger/message');
    const onReload = () => {
        scrollPosition.current = { x: window.scrollX, y: window.scrollY };
        reload();
    };

    const setRef = useCallback((node) => {
        node?.scrollIntoView({ behavior: Boolean(scrollPosition.current.y) ? "smooth" : "auto", block: 'nearest' });

        setTimeout(() => {
            window.scrollTo(scrollPosition.current.x, scrollPosition.current.y);
        }, Boolean(scrollPosition.current.y) ? 1000 : 0);
    }, [messageList]);

    useEffect(() => {
        if(data?.length) {
            setMessageList(data);
        }
    }, [data, setMessageList]);

    if(!messageList.length) {
        return null;
    }

    return (
        <div className="card card-bordered">
            <div className="messenger">
                {messageList.sort((a, b) => a.createdAt.localeCompare(b.createdAt)).map(({ id, roomId, content, files, createdBy, createdAt }) => (
                    <div className={`message-wrapper ${createdBy}`} key={id}>
                        <small className="d-flex mx-2"><b>{createdBy === "user" ? "Oznamovatel" : "Řešitel"}</b></small>
                        <small className="d-flex mb-1 mx-2">
                            {formatDate(createdAt)}
                        </small>
                        <div className="message d-flex flex-column">
                            {content}
                            {Boolean(files?.length) && (
                                <div className="mt-3">
                                    {files?.map((file, index) => (
                                        <a key={index} target="_blank" href={`/data/rooms/${roomId}/${file}`} className="d-block file">{file}</a>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                <div ref={setRef} />
            </div>
            <NewMessage onReload={onReload} />
        </div>
    );
};

const ViewMessage = () => {
    const { jti } = decodeJwt(localStorage.getItem('token'));
    const { data: roomData, isLoading: isRoomLoading } = useFetch(`messenger/room/${jti}`);

    if(isRoomLoading) {
        return null;
    }

    return (
        <div className="container">
            <div className="my-4">
                <h2>Vaše oznámení</h2>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input header">
                        Název organizace
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="input">
                        {roomData.name}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input header">
                        Oznamovatel
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="input">
                        {roomData.userName || 'Nevyplněno'}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input header">
                        Telefon
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="input">
                        {roomData.userPhone || 'Nevyplněno'}
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-3">
                    <div className="input header">
                        E-mail
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="input">
                        {roomData.userEmail || 'Nevyplněno'}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input header">
                        Kód oznámení
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="input header">
                        {roomData.roomCode}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input header">
                        Předmět oznámení
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="input">
                        {roomData.subject}
                    </div>
                </div>
            </div>
            <div className="my-3 mt-5">
                <h4>Komunikace</h4>
                <MessengerList />
            </div>
            <div className="d-flex justify-content-center">
                <Link className="lc-btn red" to="/">Zavřít oznámení</Link>
            </div>
        </div>
    );
}

export default ViewMessage;
